import React, { ReactElement } from "react";
import colors from "@styles/variables/camplejeuneapplicationVariables.module.scss";
import styles from "./styles.module.scss";

export default function WhyUs(): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    return (
        <section className={` relative px-3 ${styles.description}`}>
            <div className="lg:py-24 py-12">
                <h2>You Are Not Alone</h2>
                <p className="font-light">
                    More than a million people, including Marines, Sailors,
                    civilian workers, and their families, had severe health
                    problems because Camp Lejeune's water was contaminated by
                    dangerous chemicals and bad waste disposal methods.
                </p>
                <p className="font-light">
                    Don't wait. Seek legal support today to ensure you receive
                    the fair compensation you deserve.
                </p>
                <button
                    onClick={() => scrollToTop()}
                    style={{ backgroundColor: colors.darkBlue, color: "white" }}
                    className="lg:px-9  px-4 py-4  rounded-lg hover:opacity-95 font-semibold lg:text-lg text-base mx-auto block mt-8"
                >
                    Get My Free Lawsuit Case Review
                </button>
            </div>
        </section>
    );
}
